<template>
  <base-section id="theme-features">
    <base-section-heading title="Compétences" />

    <v-container>
      <v-row>
        <v-col
          v-for="(feature, i) in features"
          :key="i"
          cols="12"
          md="6"
        >
          <base-avatar-card
            v-bind="feature"
            align="left"
            horizontal
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md=""
          style="font-weight: bold;text-align: center"
        >
          Nous restons à disposition pour toutes propositions de projets.
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionOurDomains',

    data: () => ({
      features: [
        {
          title: 'Aménagement de véhicules',
          icon: 'mdi-car-estate',
          text: 'Aménagement sur mesure de tous types de véhicules selon les besoins',
        },
        {
          title: 'Serrurerie',
          icon: 'mdi-key-variant',
          text: 'Porte, barrière, meuble, escalier et tous autres projets en acier, inox, ou aluminium.',
        },
        {
          title: 'Construction métallique',
          icon: 'mdi-domain',
          text: 'Véranda, palan, poutraison métallique et autres structures métallique sur mesure.',
        },
        {
          title: 'Porte et fenêtre aluminium',
          icon: 'mdi-door',
          text: 'Nous fabriquons et posons des portes tôlées, semi vitrée ou complètement vitrées ainsi que des fenêtres ou vérandas sur mesures en double ou triple vitrage.',
        },
      ],
    }),
  }
</script>
